
import gql from 'graphql-tag';

export const GET_RESERVATIONS = gql`
query GetReservations($fields: GetReserveInput) 
{
  reserves(fields: $fields) {
    id
    applicantFirstName
    applicantLastName
    applicantEmail
    brand
    model
    version{
      id
      name
    }
    carLot{
      id
      name
      companyId
    }
    vehicleColor
    hexColor
    price
    clientFirstName
    clientLastName
    numberPhone
    state
    reasonForCancellation
    createdAt
    year
    condition
    patent
    branchOffice
    currencySimbol
    applicantType
  }
}`;
