
import gql from 'graphql-tag';

export const GET_QUOTATION = gql`
query GetQuotation($info: InfoCarQuoteInput) 
{
  carQuote(info: $info) {
    id
    car {
      marca
      modelo
      version
      year
      licencePlate
    }
    carInfo {
      label
      value
    }
    quote {
      prices {
        type
        amount
      }
      discounts {
        label
        value
      }
    }
    date
  }
}`;
