import gql from 'graphql-tag';

export const GET_ALL_CARS = gql`
  query GetAllCars {
    cars {
      results {
        marca,
        modelo,
        version,
        id
      }
    }
  }
`;
