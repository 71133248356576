import gql from 'graphql-tag';

export const GET_SEGMENTOS = gql`
query GetSegments($published: Boolean, $condition: Int, $all: Boolean) {
    segments(published: $published, condition: $condition, all: $all) {
            type
            name
    }
}
`;
