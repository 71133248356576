import gql from 'graphql-tag';

export const GET_MODELS = gql`
query GetModels($brandId: String, $published: Boolean, $segment: String, $requiredPrices: Boolean) {
  models(brandId: $brandId, published: $published, segment: $segment, requiredPrices: $requiredPrices) {
      id
      name
    }
}
`;
