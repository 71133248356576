import gql from 'graphql-tag';

export const GET_MODEL_YEARS = gql`
query GetModelYears($brandId : String, $modelId: String) {
  years(brandId: $brandId, modelId: $modelId) {
    label
    year
  }
}
`;
