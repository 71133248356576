import gql from 'graphql-tag'

export const GET_DATAMASTER_CARS = gql`
  query getDatamasterCars(
    $brands: String
    $models: String
    $segments: String
    $search: String
    $page: Int
    $fuel: String
    $transmission: String
  ) {
    carsMaster(
      filter: {
        where: {
          marca: $brands
          modelo: $models
          search: $search
          combustible: $fuel
          segmento: $segments
          transmision: $transmission
        }
      }
      page: $page
    ) {
      results {
        id
        brand {
          id
          name
        }
        model {
          id
          name
        }
        version {
          id
          name
        }
        segment {
          name
          type
        }
        factoryPrice
        resalePrice
        netSalePrice
        userBasedPrice
        transmission
        fuel
        km
        year
        images
        stockFisico
        carLot {
          id
          name
        }
        company {
          id
          name
          logo
        }
        stock {
          id
          estado
        }
        finalPrice
        currency_symbol
      }
      pagination {
        total
        pages
        hasMore
        actualPage
      }
    }
  }
`
