
import gql from 'graphql-tag';

export const GET_CAR_MASTER = gql`
query GetCarMaster(
  $id: String
  ) {
    carMaster(id: $id) {
        segment {
            type
            name
        }
        cacheUpdate
        fuel
        images
        pdf {
            url
            size
            name
        }
        id
        brand {
            id
            name
        }
        model {
            id
            name
        }
        factoryPrice
        resalePrice
        transmission
        version {
            id
            name
        }
        company {
            id
            logo
            name
        }
        carLot {
            id
            companyId
            name
        }
        description
        dataSheet {
            id
            title
            sections {
                id
                title
                data {
                    label
                    value
                }
            }
        }
        colors {
            id
            name
            color
        }
        manufactYear {
            from
            to
        }
        stockFisico
        specialSpecs
    }
}`;

export const GET_ATTRIBUTES_BY_SEGMENT = gql`
query GetAttributesBySegment(
  $segment: String
  ) {
    attributesBySegment(segment: $segment) {
        segment
        attributes
    }
}`;
