import gql from 'graphql-tag';

export const GET_PRODUCT = gql`
query GetProduct ($id: String) {
    product(id: $id) {
        id
        brand {
            id
            name
        }
        model {
            id
            name
        }
        version {
            id
            name
        }
        images
        price
        domain
        km
        year
        is0km
        carLot {
            id
            name
        },
        stock {
            stockIds
            id
            color {
                id
                name
                color
            }
            visible
            price
            count
            location
            branch_office
            observation
            stateReseller
            statePublic
            lastStateResellerUpdate
            lastStatePublicUpdate
            estados
        }
        currency_symbol
    }
}`;
