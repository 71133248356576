import gql from 'graphql-tag';

export const GET_CITIES = gql`
  query GetCities($idCountry: String, $idProvince: String) {
    cities(idCountry: $idCountry, idProvince: $idProvince) {
      id
      nombre
    }
  }
`;
