import gql from 'graphql-tag';

export const GET_CAR_LOTS = gql`
query GetCarLots($companyId: ID) {
    carlots(companyId: $companyId) {
      id
      name
    }
}
`;
