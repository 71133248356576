import gql from 'graphql-tag';

export const GET_BRANDS = gql`
  query GetBrands($published: Boolean, $segment: String) {
    brands(published: $published, segment: $segment) {
      id
      name
    }
  }
`;
