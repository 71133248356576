import gql from 'graphql-tag';

export const GET_USER = gql`
  query GetUser($email: String) {
    getUser(email: $email) {
        firstname
        lastname
        email
        role_name
        phone
        nroDoc
        city
        province
        country
        birth_date
        marital_status
    }
  }
`;
