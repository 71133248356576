
import gql from 'graphql-tag';

export const GET_FILTERED_CARS = gql`
query getFilteredCars(
  $filter: CarSearchableFieldsInput
  $order: SortOrder,
  $field: CarSortableField
  $page: Int
  ) {
    cars(filter: {where: $filter, sort: { field: $field, order: $order }}, page: $page) {
      results {
        segment {
          name
          type
        }
        cacheUpdate
        fuel
        images
        id
        brand {
          id
          name
        }
        model {
          id
          name
        }
        factoryPrice
        resalePrice
        netSalePrice
        userBasedPrice
        transmission
        carLot {
          id
          name
        }
        version {
          id
          name
        }
        is0km,
        km,
        year,
        currency_symbol,
        company {
          id
          name
          logo
        }
        stock {
          estado
        }
        finalPrice

        stock {
          id
          estado
        }
      }
      maxLocalResalePrice
      pagination {
        total
        pages
        hasMore
        actualPage
      }
    }
}`;


