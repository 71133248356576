import gql from 'graphql-tag';

export const GET_FEATURED_CARS_CATALOG = gql`
  query GetFeaturedCarsCatalog {
    featured {
        id
        brand {
          id
          name
        }
        model {
          id
          name
        }
        version {
          id
          name
        }
        segment {
            type
            name
        }
        images
        factoryPrice
        resalePrice
        transmission
        userBasedPrice
        fuel,
        km,
        currency_symbol,
        year,
        is0km,
        netSalePrice,
        finalPrice,
    }
  }
`;
