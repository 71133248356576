import gql from 'graphql-tag';

export const GET_SUGGESTIONS = gql`
  query ListDataSheetChanges {
    dataSheetChanges {
    id
    applicantEmail
    applicantFirstName
    applicantLastName
    carLot {
      id
      companyId
      name
    }
    brand
    model
    version
    description
    status
    createdAt
    }
  }
`;
