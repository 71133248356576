import gql from 'graphql-tag';

export const GET_MODEL_VERSIONS = gql`
query GetModelVersions($brandId : String, $modelId: String, $year: Int) {
  versions(brandId: $brandId, modelId: $modelId, year: $year) {
    id
    name
    tipo_cotizador
  }
}
`;
