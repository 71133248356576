import gql from 'graphql-tag';

export const GET_CATALOG_MODELS = gql`
query GetCatalogModels($brandId: [String], $published: Boolean, $carLotId: ID, $condition: Int, $segment: String) {
  catalogModels(brandId: $brandId, published: $published, carLotId: $carLotId, condition: $condition, segment: $segment) {
      id
      name
    }
}
`;
