
import gql from 'graphql-tag';

export const GET_REGISTRATION_REQUEST = gql`
query GetRegistrationRequest($requestRegistrationId: String)
{
  registrationRequest(requestRegistrationId: $requestRegistrationId) {
    name
    lastName
    email
    dni
    cuilCuit
  }
}`;
