import gql from 'graphql-tag';

export const GET_PRODUCTS = gql`
query GetProducts ($brand: String, $model: String, $companyId: ID, $companyName: String, $carlotId: ID, $publishCarmuv: Boolean, $is0km: Boolean, $userVisibility: String) {
	products(brand: $brand, model: $model, companyId: $companyId, companyName: $companyName, carlotId: $carlotId, publishCarmuv: $publishCarmuv, is0km: $is0km, userVisibility: $userVisibility) {
    id
    is0km
    km
    year
    visible
    domain
    stateReseller
    statePublic
    lastStateResellerUpdate
    lastStatePublicUpdate
    carLot {
      id
      name
      companyId
    }
    company {
      id
      name
      logo
    }
    brand {
      id
      name
    }
    model {
      id
      name
    }
    version {
      id
      name
    }
   segment {
    name
    type
  }
    stockFisico
    visible
  }
}`;
