import gql from 'graphql-tag';

export const GET_FEATURED_CARS_MASTER = gql`
  query GetFeaturedCarsCatalogMaster {
    featured {
        id
        brand {
          id
          name
        }
        model {
          id
          name
        }
        version {
          id
          name
        }
        segment {
            type
            name
        }
        images
        factoryPrice
        resalePrice
        transmission
        fuel
    }
  }
`;
