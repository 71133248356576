import gql from 'graphql-tag';

export const GET_CFT_QUOTE_AVG = gql`
query GetCftQuoteAvg(
  $avgQuoteValue: Float
  $calculateAvgQuote: Boolean
  $credit_line_id: Int
  $montoFinanciar: Int
  $quantity: Int
  $tna: Float
  $valorPrimeraCuota: Float
  ){
    getCftQuoteAvg(lineInfo: {
        avgQuoteValue: $avgQuoteValue
        calculateAvgQuote: $calculateAvgQuote
        credit_line_id: $credit_line_id
        montoFinanciar: $montoFinanciar
        quantity: $quantity
        tna: $tna
        valorPrimeraCuota: $valorPrimeraCuota
  }) {
    cft
    coutaProm
    valorPrimeraCuota
  }
  }
`;
