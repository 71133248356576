import gql from 'graphql-tag'

export const GET_VALIDATION_CODE = gql`
  query GetValidationCode($user: String, $code: String) {
    getValidationCode(user: $user, code: $code){
      success
      invalid
      expired
    }
  }
`
