import gql from 'graphql-tag';

export const GET_ALL_FINANCINGS = gql`
query GetAllFinancings {
  financing {
    id
    name
    brands{
      id
      name
    }
    company
    carLot
    logo
    pdf
    active
    createdAt
    updatedAt
  }
}`;

