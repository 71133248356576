import gql from 'graphql-tag';

export const GET_PROVINCES = gql`
  query GetProvinces($idCountry: String) {
    provinces(idCountry: $idCountry) {
      id
      nombre
    }
  }
`;
