import gql from 'graphql-tag';

export const GET_CATALOG_BRANDS = gql`
query GetCatalogBrands($published: Boolean, $condition: Int, $segment: String) {
    catalogBrands(published: $published, condition: $condition, segment: $segment) {
      id
      name
    }
}
`;
