
import gql from 'graphql-tag';

export const GET_CAR = gql`
query GetCar(
  $id: String
  ) {
    car(id: $id) {
        segment {
            type
            name
        }
        cacheUpdate
        fuel
        userBasedPrice
        images
        id
        brand {
            id
            name
        }
        model {
            id
            name
        }
        factoryPrice
        resalePrice
        netSalePrice
        transmission
        version {
            id
            name
        }
        company {
            id
            logo
            name
        }
        carLot {
            id
            companyId
            name
        }
        description
        dataSheet {
            id
            title
            sections {
                id
                title
                data {
                    label
                    value
                }
            }
        }
        colors {
            id
            name
            color
        }
        stock {
            price
            discount
            id
            observation
            resale_price
            color {
                id
                name
                color
            }
            estado
            estados
            activeStockId
        }
        km
        currency_symbol
        subversion
        is0km
        branchOfficeInfo {
          id
          name
        }
        year
        patent
    }
}`;
