import gql from 'graphql-tag';

export const GET_ALL_FINANCINGS_ACTIVES = gql`
query GetFinancings {
    financing(fields:{active:true}) {
        id
        name
        brands{
          id
          name
        }
        company
        carLot
        logo
        pdf
        active
        createdAt
      }
}
`;

